import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Hero from "../components/hero"
import Layout from "../components/layout"
import Title from "../components/title"

const useStyles = makeStyles(theme => ({
	grid: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(10),
	},
	content: {
		[theme.breakpoints.down('xs')]: {
			"&:not(:last-child)": {
				marginBottom: theme.spacing(10)
			}
		}
	},
	link: {
		textDecoration: 'none !important'
	},
	textLink: {
		textDecoration: 'none',
		"&:hover": {
			textDecoration: 'underline',
		},
		"&:hover:active": {
			textDecoration: 'underline',
		}
	},
	gray: {
		color: theme.palette.text.secondary,
	},
	description: {
		color: theme.palette.text.secondary,
	},
	imageContainer: {
		display: "block",
		overflow: "hidden",
		padding: theme.spacing(2),
		backgroundColor: theme.palette.common.white,
	}
}))

const AccessoryCategory = ({ data }) => {
	const classes = useStyles()
	
	const { title, image } = (data && data.datoCmsHeroImage && data.datoCmsHeroImage) || {}
	const accessories = (data && data.allDatoCmsPageAccessory && data.allDatoCmsPageAccessory.nodes) || []

	return (
		<Layout>
			<Hero title={title} image={image} />
			<Container maxWidth="lg">
				{title && (
					<Box mt={10} display="flex">
						<Title>{title}</Title>
					</Box>
				)}
				<Grid container spacing={3} className={classes.grid}>
					{accessories.map(({
						id,
						accessoryName,
						accessorySize,
						accessoryType,
						accessoryColor,
						accessoryPrice,
						accessoryImage,
						accessoryDescription,
						slug
					}) => (
						<React.Fragment key={id}>
							<Grid item md={5} sm={6} xs={12}>
								{accessoryImage && (
									<div className={classes.imageContainer}>
										<GatsbyImage image={getImage(accessoryImage)} alt={accessoryName} />
									</div>
								)}
							</Grid>
							<Grid item md={7} sm={6} xs={12} key={id} className={classes.content}>
								<Typography
									variant="h5"
									className={classes.name}
									gutterBottom
								>{accessoryName}</Typography>
								<Typography
									variant="body2"
									className={classes.gray}
									component="div"
									gutterBottom
									dangerouslySetInnerHTML={{ __html: accessoryDescription }}
								/>
								{accessoryColor && (
									<Typography
										variant="body2"
										className={classes.color}
									>
										<strong>Dostępne kolory: </strong>
										<span className={classes.gray}>{accessoryColor}</span>
									</Typography>
								)}
								{accessorySize && (
									<Typography
										variant="body2"
										className={classes.size}
										gutterBottom
									>
										<strong>Dostępne rozmiary: </strong>
										<span className={classes.gray}>{accessorySize}</span>
									</Typography>
								)}
								<Typography
									className={classes.price}
									gutterBottom
								>
									<strong>{`Cena: ${accessoryPrice} zł`}</strong>
								</Typography>
							</Grid>
						</React.Fragment>
					))}
				</Grid>
			</Container>
		</Layout>
	)
}

export const query = graphql`
  query queryAccessoryType($accessoryType: String!) {
    allDatoCmsPageAccessory(filter: {accessoryType: {eq: $accessoryType}}) {
      nodes {
        accessoryName
        accessoryDescription
        accessoryType
        accessoryPrice
        accessoryColor
        accessorySize
        id
        position
        slug
        accessoryImage {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
		datoCmsHeroImage(pageType: { eq: $accessoryType }) {
			title
			image {
				gatsbyImageData
			}
		}
  }
`

export default AccessoryCategory
